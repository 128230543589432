@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');

body {
    background-color: black;
    color: #d0d0d0;
    font-family: 'Ubuntu', sans-serif;
}

.logoWrap a {
    color: crimson;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-decoration: none;
}
.logoWrap a:hover {
    color: red;
}
.ceyButtonRed {
    background-color: crimson;
    border: red;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
}
.ceyButtonRed:hover, .ceyButtonRed:focus, .ceyButtonRedLoading {
    background-color: #f3f3f3 !important;
    border: crimson;
    color: crimson !important;
}

.fullWidthImage img {
    width: 100%;
}
.footerLeftText {
    font-size: 12px;
    color: #444444;
}

.icon_margin {
    margin-top: -2px;
}
.singleGalleryItem, .coverImageWrap img {
    border: 2px solid #3e3e3e;
    margin-bottom: 10px;
    cursor: pointer;
}
.darkBG {
    background-color: #000;
    border: none;
    color: #fff;
    border-radius: 0;
}
.darkBG .close {
    color: #fff;
}
.borderBottomTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
}
.authModeSwitchWrap {
    color: #d0d0d0;
}
.authModeSwitch {
    color: red !important;
    cursor: pointer;
    text-decoration: underline;
}
.authbg {
    background-image: url('./images/auth_bg.jpg');
    height: 100vh;
    position: relative;
}
.authbg::before {
    content: " ";
    display: block;
    clear: both;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000006b;
}

.headerSlider {
    position: relative;
}
.textCenter {
    text-align: center;
}
.headerSlider::before {
    content: " ";
    display: block;
    clear: both;
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: #0202027a;
}
.textCenter {
    z-index: 111;
    width: 100%;
    color: #fff;
}



.spin {
    font-size: 20px;
    -webkit-animation: icon-spin 1s infinite linear;
            animation: icon-spin 1s infinite linear;
}
@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}
